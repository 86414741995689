<template>
    <nav class="navbar">
        <h1 class="logo">coinny<span class="fininho">.app</span></h1>
        <ul class="fonte">
            <li><a href="https://api.whatsapp.com/send/?phone=5581984271409&text=Ol%C3%A1+Brenda%21%0AFiquei+interessado+em+saber+mais+sobre+a+coinny%2C+vamos+marcar+uma+conversa%3F+%3A%29&type=phone_number&app_absent=0" target="_blank">Contato</a>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'NavBar'
}
</script>

<style scoped>
.fininho {
    color: rgba(255, 255, 255, 0.627);
    font-family: SpartanExtraLight;
    /* Lighter font weight */
}
.fonte{
    font-family: SpartanMedium;
    font-size: 16px;
    text-decoration: none;

}
.navbar {
    display: flex;
    align-items: center;
    /* Center items vertically */
    justify-content: space-between;
    /* Spreads content to opposite ends */
    position: absolute;
    top: 5%;
    /* Adjusted for better vertical positioning */
    left: 12%;
    /* Horizontal positioning */
    width: 76%;
    /* Width as specified */
    height: 0;
    /* Fixed height for better control */
    border-radius: 35px;
    /* Rounded corners */
    background-color: #7b84fca1;
    /* Semi-transparent background */
    color: white;
    /* Text color */
    z-index: 1000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    /* Subtle shadow for depth */
    padding: 44px 74px;
    /* Padding for inner spacing */
}

.logo {
    font-size: 24px;
    /* Larger text for the logo */
    color: white;
    /* Ensuring visibility */
    font-weight: bold;
    font-family: SpartanExtraBold;
    /* Bold font for logo */
}

.navbar ul {
    list-style: none;
    display: flex;
    justify-content: space-around;
    /* Adjusted for spacing */
    margin: 0;
    padding: 0;
}

.navbar ul li {
    display: inline;
}

.navbar ul li a {
    color: white;
    /* Text color */
    text-decoration: none;
    font-size: 16px;
    /* Font size */
    padding: 10px 15px;
    /* Padding for better touch */
}

.navbar ul li a:hover {
    text-decoration: none;
    /* Hover effect */
}
@media (max-width: 1200px) {
    .navbar {
        justify-content: center;
        /* Adjusts positioning for mobile */
        background-color: transparent;
        /* Reduced padding */
    }
    

    .menu-icon {
        display: block;
        /* Shows the menu icon on small screens */
    }

    .navbar ul {
        flex-direction: column;
        /* Stack the links vertically */
        align-items: center;
        /* Center alignment for items */
        display: none;
        /* Hide initially */
    }

    .navbar ul li a {
        padding: 15px;
        /* Larger touch area */
    }
    .logo{
        font-size: 240%;
    }
    .fonte{
        display: block;

    }
}
</style>
