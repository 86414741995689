<template>
    <div class="container">
        <div class="coluna">
            <div class="logo">

                <h1 class="Coinny"><span class="rosinha">coinny</span>.app</h1>
                <h4 class="cnpj">CNPJ: 55.527.056/0001-90</h4>
                <h4 class="Cidade">RECIFE - PE</h4>
            </div>

        </div>
        <div class="social-media">
            <h5 class="redes-top">NOS SIGA NAS REDES</h5>
            <div class="row">
                <div class="row-igm">
                    <img src="../assets/social-linkedin.png" alt="Instagram" />
                    <a href="https://www.linkedin.com/in/coinny-app-2a57a1305/?original_referer=https%3A%2F%2Fwww%2Egoogle%2Ecom%2F&originalSubdomain=br"
                        target="_blank" class="links">CoinnyApp</a>
                </div>
                <div class="row-igm">
                    <img src="../assets/threads.png" alt="Instagram" />
                    <a href="https://www.instagram.com/coinnyapp/" target="_blank" class="links">coinnyapp</a>
                </div>
            </div>
            <div class="row">
                <div class="row-igm">
                    <img src="../assets/x.png" alt="Instagram" />
                    <a href="https://x.com/CoinnyApp" target="_blank" class="links">CoinnyApp</a>
                </div>
                <div class="row-igm">
                    <img src="../assets/image 1.png" alt="Instagram" />
                    <a href="https://www.tiktok.com/@coinny.app" target="_blank" class="links">CoinnyApp</a>
                </div>
            </div>
        </div>

    </div>
    <div class="bg">
        <div class="line">
            <div class="row-spaced">
                <div class="row">
                    <h3 class="direitos">©<span>2024Coinny</span></h3>
                    <h3 class="privacy">Termos de privacidade</h3>
                </div>
                <h3 class="made-by"><a href="https://www.instagram.com/ianbraga_/" target="_blank">Made by @ianbraga_</a></h3>
            </div>


        </div>
    </div>

</template>

<script>
export default {
    name: 'LastPanel'
}
</script>

<style scoped>
.rosaestranha{
    visibility: hidden;
}

a{
    text-decoration: none;
    color: #ffffff83;
}
.direitos{
    margin-left: 3em;
    margin-right: 5em;
    font-family: SpartanLight;
    color: #ffffff;
    /* Pink color */
}
.privacy{
    font-family: SpartanLight;
    color: #ffffff7e;
    /* Pink color */
}
.row{
    display: flex;
    gap: 70em;
    /* Ajusta os elementos filhos para que fiquem espaçados uniformemente */
    align-items: center;
    /* Alinha os elementos filhos verticalmente */

}
.made-by{
    color: #ffffff83;
    font-family: SpartanRegular;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6%;
    /* Pink color */
}
.row-spaced{
    display: flex;
    justify-content: space-between;
    /* Ajusta os elementos filhos para que fiquem espaçados uniformemente */
    height: 7.5em;
    background-color: #13143D;
}
.bg{
    display: flex;
        justify-content: space-between;
        /* Ajusta os elementos filhos para que fiquem espaçados uniformemente */
        height: 7.5em;
        background-color: #13143D;
}
.line{
    border-top: 1px solid #ffffff83;
    
    width: 100%;

}
.Coinny{
    font-size: 3.5em;
    margin-top: 2em;
}
.Cidade {
    color: #ffffff83;
    font-family: SpartanRegular;
    margin-top: 1%;
    /* Pink color */
}
.cnpj {
    color: #ffffff83;
    font-family: SpartanRegular;
    margin-top: 3%;
    /* Pink color */
}
.links {
    color: white;
    text-decoration: none;
    font-family: SpartanLight;
    /* Remove o sublinhado do link */
}
.row-igm{
    display: flex;
    gap: 10%;
    /* Ajusta os elementos filhos para que fiquem espaçados uniformemente */
    align-items: center;
    /* Alinha os elementos filhos verticalmente */
}
.row{
    display: flex;
    gap: 30%;
    /* Ajusta os elementos filhos para que fiquem espaçados uniformemente */
    align-items: center;
    /* Alinha os elementos filhos verticalmente */
}
.redes-top {
    color: #ffffff83;
    margin-bottom: 7%;
    font-family: SpartanRegular;
    /* Pink color */
}
.rosinha {
    color: #FF657D;
    /* Pink color */
}
.container {
    display: flex;
    justify-content: space-between;
    /* Ajusta os elementos filhos para que fiquem espaçados uniformemente */
    align-items: top;
    height: 20em;
    background-color: #13143D;
}

/* Adiciona estilos específicos para o logo e a coluna das redes sociais */
.logo,
.social-media {
    padding: 0 5em;
    color: white;
    /* Adiciona um padding horizontal para cada lado */
}

.social-media {
    display: flex;
    flex-direction: column;
    margin-top: 7em;
    margin-left: 24em;
    /* Alinha os elementos verticalmente */
}

.logo {
    flex: 1;
    /* Permite que o logo ocupe 1/3 do espaço */
}

.social-media {
    flex: 0.6;
    /* Permite que a coluna das redes sociais ocupe 2/3 do espaço */
}
@media (max-width: 1200px) {
    .container {
        flex-direction: column;
        align-items: flex-start;
        /* Aligns everything to the left */
        justify-content: flex-start;
        /* Starts content from the top */
        width: 100%;
        height: auto;
        /* Adjust height to auto */
        /* Add padding to avoid sticking to the edges */
    }

    .logo,
    .social-media {
        padding: 0;
        /* Reset padding */
        margin: 0;
        margin-top: 2.5em;
        /* Reset margin */
        /* Full width */
        align-items: flex-start;
        /* Align items to the left */
    }

    .social-media{
        margin-left: 2em;
    }

    .logo {
        text-align: left;
        font-size: 1.3em;
        margin-left:1.5em;
        top: 0;
        /* Align text to the left */
    }

    .row {
        flex-direction: column;
        /* Stack items vertically */
        gap: 0.5em;
        /* Smaller gap between items */
        align-items: flex-start;
        /* Align items to the left */
    }

    .row-igm {
        justify-content: flex-start;
        /* Align images and links to the left */
    }

    .row-spaced {
        flex-direction: column;
        /* Stack footer elements vertically */
        gap: 0;
        /* Adjust gap for vertical stacking */
        align-items: center;
        margin-top: 1em;
        /* Align to the left */
    }

    .made-by,
    .direitos,
    .privacy {
        text-align: left;
        /* Align text to the left */
        width: 100%;
        /* Ensure full width usage */
    }

}
@media (max-width: 1200px) {
    .rosaestranha{
        visibility: hidden;
        display: none;
    }
    .coluna{
        justify-content: left;
    }
    .Coinny {
        font-size: 2.5em;
        /* Smaller font size for the logo */
    }

    .row-igm{
   
        margin-top: 0.5em
        /* Adjust gap between images and links */
    }

    .cnpj,
    .Cidade,
    .redes-top,
    .links,
    .direitos,
    .privacy,
    .made-by {
        font-size: 0.8em;
        /* Adjust text size for readability on small devices */
    }

    .direitos,
    .privacy {
            text-align: left;
            /* Align text to the left */
            width: 100%;
            /* Ensure full width usage */
    }

    img {
        display: block;
        /* Ensure images are not larger than their container */
        height: auto;
        /* Maintain aspect ratio */
    }
        .line {
            border-top: 1px solid #ffffff83;
            margin-top: 4%;
    
            width: 100%;

    
        }
}
</style>
