<template>
  <div id="app" class="app-container">

    <HeaderCoinny/>
    <LastPanel/>
    <router-view/>
  </div>
</template>

<script>
import HeaderCoinny from './components/HeaderCoinny.vue'
import LastPanel from './components/LastPanel.vue';
/* import BenefitsVue from './components/BenefitsVue.vue';
import ForWho from './components/ForWho.vue';  
import CadastroCoinny from './components/CadastroCoinny.vue'; */  

export default {
  name: 'App',
  components: {
    HeaderCoinny,
    LastPanel
  }
}
</script>

<style>
@font-face {
  font-family: SpartanRegular;
  src: url(./fonts/Spartan-Regular.ttf);
}
@font-face {
  font-family: SpartanBold;
  src: url(./fonts/Spartan-ExtraBold.ttf);
}
@font-face {
  font-family: SpartanMedium;
  src: url(./fonts/Spartan-Medium.ttf);
}
@font-face {
  font-family: SpartanSemiBold;
  src: url(./fonts/Spartan-SemiBold.ttf);
}
@font-face {
  font-family: SpartanLight;
  src: url(./fonts/Spartan-Light.ttf);
}
@font-face {
  font-family: SpartanExtraLight;
  src: url(./fonts/Spartan-ExtraLight.ttf);
}
@font-face {
  font-family: SpartanThin;
  src: url(./fonts/Spartan-Thin.ttf);
}
@font-face {
  font-family: SpartanBlack;
  src: url(./fonts/Spartan-Black.ttf);
}
@font-face {
  font-family: SpartanExtraBold;
  src: url(./fonts/Spartan-ExtraBold.ttf);
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: SpartanBold;
  scroll-behavior: smooth;
}

.bonita {
  transition: transform 0.4s, filter 0.4s;
  /* Smooth transition for transform and filter */
}

.bonita:hover {
  transform: scale(1.1);
  /* Scale the image on hover */
  filter: invert(100%);
  /* Invert colors fully */
}

</style>
