<template>
    <div class="sombra"><div class="container">
        <img src="../assets/IG Icon.png" alt="Coinny Logo" class="imagem-topo" />
        <NavBar class="celularzinho" />
        <div class="header">
            <h1><span class="negrito">EDUCAÇÃO FINANCEIRA</span> PARA</h1>
            <h1>TODOS, EM<SPAn class="negrito"> QUALQUER LUGAR</SPAn></h1>
        </div>
        <div class="subtitle">
            <p>Aprenda os pilares básicos da educação financeira de forma totalmente gratuita, à distância de um toque.
            </p>
        </div>
        <a class="button" href="https://linktr.ee/coinny" target="_blank">CONHEÇA A COINNY</a>
        <img src="../assets/Group 1.png" alt="Decorative Element" class="imagem-rodape" />

    </div>
    <div class="baixo">
        <img src="../assets/bannerpage1.png" alt="" class="banner">
    </div></div>
    

</template>

<script>
import NavBar from './NavbarCoinny.vue'
export default {
    name: 'FinancialEducation',
    components: {
        NavBar
    },
    
    methods: {
        testNow() {
            // Define what happens when the user clicks the button
            console.log("Button clicked");
        }
    }   
} 

</script>

<style scoped>
.negrito {
    color: #ffffff;
}
.baixo{
    position: relative;
    display: flex;
    flex-direction: column;
    height: auto;
    background-color: #5c67f2;
    color: white;
    text-align: center;
}
.banner {
    width: 100%;
    /* Full width */
    height: auto;
    /* Maintain aspect ratio */
    object-fit: cover;
    border-radius: 0;
    margin: 0;
    padding: 0;
    /* Cover the entire area */
}
.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: radial-gradient(circle at center, #6b74f9, #535efe 60%, #4a57df);
    color: white;
    text-align: center;

    /* Adding drop shadow */
    
    /* Horizontal offset, Vertical offset, Blur radius, Shadow color */
}

.imagem-topo {
    position: absolute;
    top: 0px;
    /* Adjust according to your design */
    left: 0px;
    width: 12%;
    /* Adjust according to your design */
}

.imagem-rodape {
    position: absolute;
    bottom: 0px;
    /* Adjust according to your design */
    right: 0px;
    width: 25%;
    /* Adjust according to your design */
}

.header {
    margin-top: 5%;
}

.header h1 {
    font-size: 50px;
    margin: 0;
    color: #ffffff82;
    font-weight: 900;
    font-weight: bold;
}

.subtitle p {
    font-size: 20px;
    margin: 0 20px;
    padding: 20px 0;
    max-width: 600px;
    line-height: 1.4;
    font-family: SpartanRegular;
}

.button {
    padding: 20px 54px;
    font-size: 14px;
    border-radius: 35px;
    border: none;
    background-color: white;
    color: #13143D;
    cursor: pointer;
    margin-top: 50px;
    font-family: SpartanMedium;
    transform: scale(1.02);
    transition: transform 0.5s, filter 0.5s;
    text-decoration: none;
}

button:hover {
    transform: scale(1.05);
}
@media (max-width: 1200px) {
    .imagem-topo {
        width: 12%;
        /* Larger size for desktop */
    }

    .imagem-rodape {
        width: 30%;
        /* Larger size for desktop */
    }
    .header{
        margin-top: 20%;
    }

        .header h1 {
            font-size: 1.6em;
            width: 90%;
            /* Adjusted width to be less */
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            
            /* Larger font size for desktop */
        }

    .subtitle p {
        font-size: 1em;
        width: 80%;
            /* Adjusted width to be less */
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            margin-top: 5%;
            margin-bottom: 5%;
        /* Larger font size for desktop */
    }

    button {
        padding: 10px 10px;
        font-size: 0.5em;
        margin-top: 10px;
        /* Larger padding for desktop */
    }
    
}
</style>
